import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../utils/Urls";

function Rewards() {
  const [loading, setLoading] = useState(true); // Tracks loading state
  const [nextLevel, setNextLevel] = useState(0); // Tracks the current in-progress level
  const [achievements, setAchievements] = useState({
    units: 0,
    children: 0,
  }); // Tracks achieved units and children for the current level
  const [nextLevelsData, setNextLevelsData] = useState([]); // Stores nextLevels data

  const levels = [
    { level: 1, reward: 1000, target: { units: 3, child: 3 } },
    { level: 2, reward: 3000, target: { units: 9, child: 9 } },
    { level: 3, reward: 9000, target: { units: 27, child: 27 } },
    { level: 4, reward: 27000, target: { units: 81, child: 81 } },
    { level: 5, reward: 75000, target: { units: 243, child: 243 } },
    { level: 6, reward: 250000, target: { units: 729, child: 729 } },
    { level: 7, reward: 650000, target: { units: 2187, child: 2187 } },
    { level: 8, reward: 2000000, target: { units: 6561, child: 6561 } },
    { level: 9, reward: 6500000, target: { units: 19683, child: 19683 } },
    { level: 10, reward: 20000000, target: { units: 59049, child: 59049 } },
  ];

  // Fetch reward data
  useEffect(() => {
    const fetchRewardData = async () => {
      try {
        const response = await axios.post(`${BASE_URL}user/calculate-rewards`);

        const { nextLevel, units, children } = response.data;
        const nextLevels = response.nextLevels
        // Set state with fetched data
        setNextLevel(nextLevel);
        setAchievements({ units, children });
        setNextLevelsData(nextLevels || []); // Store nextLevels data
      } catch (error) {
        console.error("Error fetching rewards data:", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchRewardData();
  }, []);

  // Determine status for each level
  const getStatus = (level) => {
    if (level < nextLevel) return "Achieved"; // Levels less than NextLevel are Achieved
    if (level === nextLevel) return "In Progress"; // Current NextLevel is In Progress
    return "Pending"; // Remaining levels are Pending
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-userGradiant">
              <tr>
                <th scope="col" className="px-6 py-3">Level</th>
                <th scope="col" className="px-6 py-3">Reward</th>
                <th scope="col" className="px-6 py-3">Target (Units)</th>
                <th scope="col" className="px-6 py-3">Target Member</th>
                <th scope="col" className="px-6 py-3">Achieved (Units)</th>
                <th scope="col" className="px-6 py-3">No. of Member(level)</th>
                <th scope="col" className="px-6 py-3">Remaining</th>
                <th scope="col" className="px-6 py-3">Achievement</th>
                <th scope="col" className="px-6 py-3">Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className="px-6 py-4 text-center text-white">
                    Loading...
                  </td>
                </tr>
              ) : (
                levels.map((item) => {
                  const status = getStatus(item.level);
                  const isCurrentLevel = item.level === nextLevel;

                  // Remaining units and members for the current level
                  const remainingUnits = isCurrentLevel
                    ? Math.max(0, item.target.units - achievements.units)
                    : "-";
                  const remainingChildren = isCurrentLevel
                    ? Math.max(0, item.target.child - achievements.children)
                    : "-";
                  const achievedUnits = isCurrentLevel
                    ? achievements.units
                    : "-";
                  const achievedChildren = isCurrentLevel
                    ? achievements.children
                    : "-";

                  // Fetch data for the next levels
                  const nextLevelData = nextLevelsData.find(
                    (next) => next.level === item.level
                  );
                  const nextAchievedUnits = nextLevelData
                    ? Math.floor(nextLevelData.totalBusiness / 15000)
                    : "-";
                  const nextAchievedChildren = nextLevelData
                    ? nextLevelData.children
                    : "-";
                  const nextRemainingUnits = nextLevelData
                    ? item.target.units - nextAchievedUnits
                    : "-";

                  // Merge current and next level data
                  const finalAchievedUnits =
                    nextAchievedUnits !== "-" ? nextAchievedUnits : achievedUnits;
                  const finalAchievedChildren =
                    nextAchievedChildren !== "-"
                      ? nextAchievedChildren
                      : achievedChildren;
                  const finalRemainingUnits =
                    nextRemainingUnits !== "-"
                      ? `${nextRemainingUnits} Units`
                      : remainingUnits;

                  return (
                    <tr
                      key={item.level}
                      className={`border-b hover:bg-blue-900 ${
                        status === "In Progress" ? "neon-border" : ""
                      }`}
                    >
                      <td className="px-6 py-4 text-white">{item.level}</td>
                      <td className="px-6 py-4 text-white">
                        ₹ {item.reward.toLocaleString()}
                      </td>
                      <td className="px-6 py-4 text-white">
                        {item.target.units}
                      </td>
                      <td className="px-6 py-4 text-white">
                        {item.target.child}
                      </td>
                      <td className="px-6 py-4 text-white">
                        {finalAchievedUnits}
                      </td>
                      <td className="px-6 py-4 text-white">
                        {finalAchievedChildren}
                      </td>
                      <td className="px-6 py-4 text-white">
                        {finalRemainingUnits}
                      </td>
                      <td
                        className={`px-6 py-4 font-medium ${
                          status === "Achieved"
                            ? "text-green-500"
                            : status === "In Progress"
                            ? "text-yellow-500"
                            : "text-red-500"
                        }`}
                      >
                        {status}
                      </td>
                      <td className="px-6 py-4">
                        {status === "In Progress" && (
                          <button
                            type="button"
                            className="px-3 py-1 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600"
                            onClick={() =>
                              alert(`Working on Level ${item.level}`)
                            }
                          >
                            Work on This
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Rewards;