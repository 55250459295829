import React, { useEffect, useState } from "react";
import { getAllMemberListServices } from "../../../services/user/UserServices";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import moment from "moment";
import { getStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";
import loader from "../../../assets/images/loader.gif";
import {
  getDateAndTimeByTimestamp,
  getDateByTimestamp,
} from "../../../utils/DateAndTimeFunctions";

function TeamList() {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null); // Initialize as null

  // Fetch userId from local storage and set it in state
  useEffect(() => {
    const storeData = getStorageValue("userBasicDetail");
    if (storeData?._id) {
      setUserId(storeData._id);
    }
  }, []);

  // Fetch members when userId changes
  useEffect(() => {
    if (userId) {
      fetchMembers(); // Fetch all members
    }
  }, [userId]);

  // Fetch all members
  const fetchMembers = async () => {
    if (!userId) {
      console.error("User ID is required but not found.");
      return;
    }

    setLoading(true); // Show loader
    try {
      const response = await getAllMemberListServices(userId); // Fetch all members
      if (response?.result) {
        setListData(response.result); // Update list data
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <div className="h-screen scrollable-content">
      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <img src={loader} alt="Loading..." className="w-16 h-16" />
        </div>
      ) : (
        <div className="grid lg:grid-cols-1 grid-cols-1 px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-white uppercase bg-userGradiant">
                <tr>
                  <th scope="col" className="w-10 p-4">
                    Sl
                  </th>
                  <th scope="col" className="w-32 px-6 py-3">
                    Member Id
                  </th>
                  <th scope="col" className="w-40 px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="w-60 px-6 py-3 truncate">
                    Email
                  </th>
                  <th scope="col" className="w-32 px-6 py-3">
                    Mobile
                  </th>
                  <th scope="col" className="w-40 px-6 py-3">
                    Referred By
                  </th>
                  <th scope="col" className="w-40 px-6 py-3">
                    Parent Id
                  </th>
                  <th scope="col" className="w-20 px-6 py-3">
                    Level
                  </th>
                  <th scope="col" className="w-20 px-6 py-3">
                    Unit
                  </th>
                  <th scope="col" className="w-40 px-6 py-3 truncate">
                    Joining Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {listData.map((ele, ind) => (
                  <tr key={ind} className="border-b hover:bg-blue-900">
                    <td className="p-4 text-center">{ind + 1}</td>
                    <td className="px-6 py-4 text-white truncate">
                      {ele?.ownId}
                    </td>
                    <td className="px-6 py-4 text-white truncate">
                      {ele?.name}
                    </td>
                    <td className="px-6 py-4 text-white truncate">
                      {ele?.email}
                    </td>
                    <td className="px-6 py-4 text-white">{ele?.mobile}</td>
                    <td className="px-6 py-4 text-white truncate">
                      {ele?.referredBy}
                    </td>
                    <td className="px-6 py-4 text-white truncate">
                      {ele?.parentId}
                    </td>
                    <td className="px-6 py-4 text-center text-white">
                      {ele?.level}
                    </td>
                    <td className="px-6 py-4 text-center text-white">
                      {ele?.joiningAmount / 15000}
                    </td>
                    <td className="px-6 py-4 text-white truncate">
                      {getDateByTimestamp(ele?.RegistrationDate)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default TeamList;
