import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo.png";
import bgVideo from "../../../assets/images/bannerVideo.mp4";
import { setStorageValue } from "../../../services/Miscellaneous/LocalStorageServices";
import { useNavigate } from "react-router-dom";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { errorTost,successTost } from "../../../utils/Helper";
import {
  getUserDetailService,
  uploadScreenshotService,
  updatePaymentDetailsServices,
  createActivationRequestService,
  verifyCryptoPaymentService,
} from "../../../services/user/UserServices";

function Payment() {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [depositDate, setDepositDate] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [cryptoOption, setCryptoOption] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [currency, setCurrency] = useState("INR"); // Default to INR
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const bankDetails = {
    accountName: "Divya Drishti International ",
    accountNumber: "6267002100000521",
    ifscCode: "PUNB0626700",
    upiId: "dcp.monavie@ybl",
    walletAddress: "0xDb1bA8118Cc7dD46c629fBB56ce16639fF13A454",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getUserDetailService();
        let { email } = response.result;
        let amount = response?.result?.joiningAmount;
        setUserDetails({ ...response.result, amount });
        setStorageValue(
          "userDetails",
          JSON.stringify({ email, amount, status: "pending" })
        );
      } catch (error) {
        errorHandler(error);
      }
    };
    fetchData();
  }, []);

  const handleCryptoWalletConnect = () => {
    setIsWalletConnected(true);
  };

  const handlePaymentTypeChange = (e) => {
    const selectedPaymentType = e.target.value;
    setPaymentType(selectedPaymentType);
    setCurrency(selectedPaymentType === "Crypto" ? "USDT" : "INR"); // Update currency based on payment type
  };
  

  const handleCryptoPayment = () => {
    setUploadedFile({ name: "Crypto Payment Successful", path: "crypto_paid" });
  };

  const isSubmitEnabled = () => {
    if (!paymentType) return false;
    if (paymentType === "UPI")
      return transactionId && depositDate && uploadedFile;
    if (paymentType === "NEFT")
      return transactionId && depositDate && uploadedFile;
    if (paymentType === "Bank Transfer")
      return depositDate && transactionId && uploadedFile;
    if (paymentType === "Crypto") {
      if (cryptoOption === "WalletTransfer")
        return transactionHash && depositDate;
      if (cryptoOption === "DirectPay")
        return isWalletConnected && uploadedFile;
    }
    return false;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const picturePath = await uploadScreenshotService(file);
        setUploadedFile({ name: file.name, path: picturePath.path });
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      userId: userDetails._id, // Ensure the logged-in user's ID is included
      email: userDetails.email,
      amount: userDetails.amount, // Add the payable amount
      imageURL: uploadedFile?.path, // Rename from paymentimage
      transactionId, // Already present
      currency: paymentType === "Crypto" ? "USDT" : "INR",
      depositDate: new Date(depositDate).getTime(), // Convert deposit date to Unix timestamp
      transactionDate: Date.now(), // Current timestamp in milliseconds
      mode: paymentType, // Mode of payment
      narration: "For Registration", // Add a description for the transaction
    };
   
    try {
      if (paymentType === "Crypto" && cryptoOption === "Crypto DirectPay") {
        // Verification logic for Crypto Direct Pay
        const verificationPayload = {
          transactionHash,
          userId: userDetails._id,
        };
  
        const verificationResponse = await verifyCryptoPaymentService(verificationPayload);
  
        if (verificationResponse.status !== "verified") {
          errorTost("Crypto payment verification failed!");
          return;
        }
  
        // Call updatePaymentDetailsServices for Crypto Direct Pay
        await updatePaymentDetailsServices(payload);
        successTost("Crypto payment successfully updated!");
      } else {
        // Create Activation Request for other cases
        await createActivationRequestService(payload);
        successTost("Activation request successfully created!");
      }
  
      setShowModal(true);
    } catch (error) {
      errorTost(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  return (
    <div className="relative w-screen h-screen overflow-y-auto">
      <video
        autoPlay
        muted
        loop
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      >
        <source src={bgVideo} type="video/mp4" />
      </video>

      <div className="relative z-10 bg-white bg-opacity-90 max-w-3xl mx-auto mt-10 shadow-2xl rounded-2xl py-10 px-6 flex flex-col items-center">
        <img className="h-20 w-auto mb-4" src={logo} alt="Logo" />
        <h2 className="text-2xl font-bold text-gray-800 mb-6">
          Payment Details
        </h2>

        <div className="w-full mb-6">
          <p className="text-gray-600 text-lg">
            Email:{" "}
            <span className="font-semibold text-gray-800">
              {userDetails.email}
            </span>
          </p>
          <p className="text-gray-600 text-lg mt-2">
            Total Payable Amount:{" "}
            <span className="font-semibold text-gray-800">
              ₹{userDetails.amount} OR {(userDetails.amount / 90).toFixed(2)}{" "}
              USDT
            </span>
          </p>
        </div>
  

        <div className="w-full mb-6">
  <h3 className="text-lg font-bold text-gray-700 mb-4">Payment Method</h3>
  <div>
    {["UPI", "NEFT", "Bank Transfer", "Crypto"].map((method) => (
      <label key={method} className="inline-flex items-center mr-4">
        <input
          type="radio"
          name="paymentType"
          value={method}
          onChange={handlePaymentTypeChange} // Updated onChange logic
          className="form-radio text-blue-500"
        />
        <span className="ml-2">{method}</span>
      </label>
    ))}
  </div>
</div>




        {paymentType === "UPI" && (
          <div>
            <p className="text-gray-700 mb-2">UPI ID: {bankDetails.upiId}</p>
            <input
              type="text"
              placeholder="Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="date"
              value={depositDate}
              onChange={(e) => setDepositDate(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
          </div>
        )}

        {paymentType === "NEFT" && (
          <div>
            <p className="text-gray-700 mb-2">
              Bank Account: {bankDetails.accountNumber} ({bankDetails.ifscCode})
            </p>
            <input
              type="text"
              placeholder="Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="date"
              value={depositDate}
              onChange={(e) => setDepositDate(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
          </div>
        )}

        {paymentType === "Bank Transfer" && (
          <div>
            <p className="text-gray-700 mb-2">
              Bank Account: {bankDetails.accountName} ({bankDetails.ifscCode})
            </p>
            <input
              type="text"
              placeholder="Transaction ID"
              value={transactionId}
              onChange={(e) => setTransactionId(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="date"
              value={depositDate}
              onChange={(e) => setDepositDate(e.target.value)}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
          </div>
        )}

        {paymentType === "Crypto" && (
          <div>
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="cryptoOption"
                  value="Crypto Wallet Transfer"
                  onChange={(e) => setCryptoOption(e.target.value)}
                  className="form-radio text-blue-500"
                />
                <span className="ml-2">Wallet Transfer</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input
                  type="radio"
                  name="cryptoOption"
                  value="Crypto DirectPay"
                  onChange={(e) => setCryptoOption(e.target.value)}
                  className="form-radio text-blue-500"
                />
                <span className="ml-2">Direct Pay</span>
              </label>
            </div>

            {cryptoOption === "Crypto Wallet Transfer" && (
              <div>
                <p className="text-gray-700 mb-2">
                  Wallet Address: {bankDetails.walletAddress}
                </p>
                <input
                  type="text"
                  placeholder="Transaction Hash"
                  value={transactionHash}
                  onChange={(e) => setTransactionHash(e.target.value)}
                  className="w-full border-gray-300 rounded-lg p-2 mb-4"
                />
                <input
                  type="date"
                  value={depositDate}
                  onChange={(e) => setDepositDate(e.target.value)}
                  className="w-full border-gray-300 rounded-lg p-2 mb-4"
                />
                 <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="w-full border-gray-300 rounded-lg p-2 mb-4"
            />
              </div>
            )}

            {cryptoOption === "Crypto DirectPay" && (
              <div>
                {!isWalletConnected ? (
                  <button
                    onClick={handleCryptoWalletConnect}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mb-4"
                  >
                    Connect Wallet
                  </button>
                ) : (
                  <button
                    onClick={handleCryptoPayment}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg mb-4"
                  >
                    Pay
                  </button>
                )}
              </div>
            )}
          </div>
        )}

        {paymentType && (
          <button
            className={`w-full mt-6 ${
              !isSubmitEnabled()
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-700"
            } text-white font-bold py-2 px-4 rounded-lg`}
            disabled={!isSubmitEnabled()}
            onClick={handleSubmit}
          >
            Submit Payment Details
          </button>
        )}
      </div>
    </div>
  );
}

export default Payment;
