import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FaFileAlt } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaUsersBetweenLines } from "react-icons/fa6";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GiProfit } from "react-icons/gi";
import { FaThumbsDown } from "react-icons/fa";
import Confetti from "react-confetti";
import UserMoneyBox from "../../../components/common/UserMoneyBox";
import NormalBox from "../../../components/common/normalBox.js";
import AccountDetailsBox from "../../../components/common/accountBox.js";
import { VscLiveShare } from "react-icons/vsc";
import { MdOutlineDateRange } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { PiUserFocusThin } from "react-icons/pi";
import { MdOutlineNumbers } from "react-icons/md";
import { errorHandler } from "../../../services/Miscellaneous/ErrorHandlerServices";
import { Pie } from "react-chartjs-2";
import {
  activateUserService,
  createDepositServices,
  generateOtpForp2pServices,
  generateOtpForWithdrawServices,
  getUserDetailService,
  upgradePlanServices,
  verifyOtpForp2pServices,
  verifyUser,
  verifyUserByEmailService,
  withdrawServices,
} from "../../../services/user/UserServices";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { errorTost, successTost } from "../../../utils/Helper";
import { useNavigate } from "react-router-dom";
import { IoIosSettings } from "react-icons/io";
import { getDDMMYYYYByTimestamp } from "../../../utils/DateAndTimeFunctions.js";
import {
  getStorageValue,
  setStorageValue,
} from "../../../services/Miscellaneous/LocalStorageServices";
import { BASE_FILE_PATH, PAYMENT_URL } from "../../../utils/Urls.js";
import { getRewardlistservice } from "../../../services/auth/AuthServices.js";

export const UserHome = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const navigate = useNavigate();

  const [dashboardDetails, setDashboardDetails] = useState({});
  const [levelData, setLevelData] = useState(null);
  const [depositModal, setDepositeModal] = useState(false);
  const [widthDrawModal, setWidthDrawModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rewardLevel, setRewardLevel] = useState(null);
  const [isppModal, setIsppModal] = useState(false);
  const [isActivateOtherIdmodal, setActivateOtherIdmodal] = useState(false);

  const profileImage =
    `${BASE_FILE_PATH}/files/${dashboardDetails.image}` ||
    "https://cdn.vectorstock.com/i/2000v/51/87/student-avatar-user-profile-icon-vector-47025187.avif";

  useEffect(() => {
    const dashboardData = async () => {
      try {
        let response = await getUserDetailService();
        setStorageValue("userBasicDetail", response?.result);
        setDashboardDetails(response.result);
      } catch (error) {
        errorHandler(error);
      }
    };
    dashboardData();
    fetchData();
  }, []);
  // Fetch reward list and check user ID
  const fetchData = async () => {
    try {
      const checkReward = await getRewardlistservice();
      const matchedReward = checkReward.result.data.find(
        (reward) => reward.userId === dashboardDetails._id
      );

      if (matchedReward) {
        setRewardLevel(matchedReward.achievedLevel); // Set achieved level for modal
        setLevelData(matchedReward); // Set matched reward details to levelData
        setShowModal(true); // Show modal
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const autpTopUpHandler = async () => {
  //   setTopStatus(!topStatus);
  //   let payload = {
  //     updateAutoTopupEnabled: !topStatus,
  //   };
  //   try {
  //     let response = await updateAutoTopServices(payload);
  //     // successTost()
  //   } catch (error) {
  //     errorHandler(error);
  //   }
  // };

  const maximum = dashboardDetails.joiningAmount * 3 ?? 0;
  const achievement = dashboardDetails.totalIncome ?? 0;

  const pieData = {
    labels: ["Achieved", "Remaining"],
    datasets: [
      {
        label: "Income Chart 3x",
        data: [achievement, Math.max(maximum - achievement, 0)], // Ensure no negative values
        backgroundColor: ["#AFDC8F", "#92C5F9"], // Colors for Achieved and Remaining
        // hoverBackgroundColor: ["#00FFCC", "#BBBBBB"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div className="h-screen scrollable-content relative">
      <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
        <button
          onClick={() => {
            navigate("/user/RefferAndEarn");
          }}
        >
          <NormalBox name="Refer & Earn" icon={<VscLiveShare size={32} />} />
        </button>
        <NormalBox
          name="Date of Joining"
          textData={getDDMMYYYYByTimestamp(dashboardDetails?.activateDate)}
          icon={<MdOutlineDateRange size={32} />}
        />
        <NormalBox
          name="CustomerID"
          textData={dashboardDetails?.ownId}
          icon={<PiUserFocusThin size={32} />}
        />
        <NormalBox
          name="Units Buy"
          textData={dashboardDetails?.joiningAmount / 15000}
        />
      </div>
      <h1 className="lg:text-2xl font-bold bg-gradient-to-l from-transparent to-blue-900 text-white m-5 p-5 rounded-2xl">
        {" "}
        Income Received Till {getDDMMYYYYByTimestamp(Date.now())}
      </h1>
      <div className="grid lg:grid-cols-3 grid-cols-1 text-white">
        <UserMoneyBox
          name="Total Income"
          // percent={0}
          money={dashboardDetails?.totalIncome}
          icon={<FaFileAlt size={32} />}
        />

        <UserMoneyBox
          name="Direct Income"
          percent={0}
          money={dashboardDetails?.directIncome}
          icon={<GiProfit size={32} />}
        />
        <UserMoneyBox
          name="Reward Income"
          percent={0}
          money={dashboardDetails?.rewardIncome}
          icon={<GiProfit size={32} />}
        />
      </div>
      <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
        <UserMoneyBox
          name="Promotional Bonus"
          money={dashboardDetails?.promotionalBonus}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="ROI"
          money={dashboardDetails?.roiIncome}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="Growth Bonus"
          money={dashboardDetails?.growthIncome}
          icon={<PiUserFocusThin size={32} />}
        />
        <UserMoneyBox
          name="Lost Income"
          percent={0}
          money={dashboardDetails?.lostIncome}
          icon={<FaThumbsDown size={32} />}
          boxClass="bg-gradient-to-r from-red-500 to-red-700 text-white" // Pass a custom class for red shade
        />
      </div>

      <div className="flex flex-wrap-reverse">
        <div className="flex  items-center px-4 py-5 m-4 bg-gradient-to-l from-transparent to-blue-900 rounded-2xl border-[0.5px] border-white/10 shadow-xl w-[100vw] lg:w-[50%] ">
          <img
            className="h-20 w-20 lg:h-40 lg:w-40 rounded-full"
            src={profileImage}
          />
          <div className="grid grid-cols-1 text-white ml-3">
            <div className="flex items-center">
              <p className="text-gray-300 text-sm">Welcome back,</p>
              <button onClick={() => navigate("/user/profile")}>
                <IoIosSettings className="text-3xl text-white mx-2" />
              </button>
            </div>
            <span className="text-white text-3xl font-bold mt-3">
              {dashboardDetails?.name}
            </span>
            <p className="mb-2">{dashboardDetails?.email}</p>
          </div>
        </div>
        <div className="300 bg-blue-600/10 py-3 rounded-lg m-4 w-[95vw] lg:w-[40%]">
          <UserMoneyBox
            name="Wallet"
            percent={0}
            money={
              dashboardDetails?.walletAmount !== undefined
                ? dashboardDetails.walletAmount.toFixed(2)
                : "0.00"
            }
            icon={<IoWalletOutline color="white" size={32} />}
          />
          <div className="p-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Deposit Button */}
            <button
              onClick={() => setDepositeModal(true)}
              className="flex items-center justify-center text-white font-semibold rounded-lg text-sm p-3 shadow-md"
              style={{
                background: "linear-gradient(90deg, #6DD5FA, #2980B9)",
              }}
            >
              <span className="material-icons mr-2">account_balance</span>
              <p>Deposit</p>
            </button>

            {/* Withdraw Request Button */}
            <button
              onClick={() => navigate("/user/withdrawalrequest")}
              className={`flex items-center justify-center font-semibold rounded-lg text-sm p-3 shadow-md ${
                dashboardDetails?.walletAmount > 1000
                  ? ""
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              style={{
                background:
                  dashboardDetails?.walletAmount > 1000
                    ? "linear-gradient(90deg, #fbc2eb, #a18cd1)"
                    : undefined,
              }}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <span className="material-icons mr-2">attach_money</span>
              <p>Withdraw Request</p>
            </button>

            {/* Withdraw USDT Button */}
            <button
              onClick={() => setWidthDrawModal(true)}
              className={`flex items-center justify-center font-semibold rounded-lg text-sm p-3 shadow-md ${
                dashboardDetails?.walletAmount > 1000
                  ? ""
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              style={{
                background:
                  dashboardDetails?.walletAmount > 1000
                    ? "linear-gradient(90deg, #89f7fe, #66a6ff)"
                    : undefined,
              }}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <span className="material-icons mr-2">currency_exchange</span>
              <p>Withdraw USDT</p>
            </button>

            {/* P2P Transfer Button */}
            <button
              onClick={() => setIsppModal(true)}
              className={`flex items-center justify-center font-semibold rounded-lg text-sm p-3 shadow-md ${
                dashboardDetails?.walletAmount > 1000
                  ? ""
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              style={{
                background:
                  dashboardDetails?.walletAmount > 1000
                    ? "linear-gradient(90deg, #ff9a9e, #fecfef)"
                    : undefined,
              }}
              disabled={!(dashboardDetails?.walletAmount > 1000)}
            >
              <span className="material-icons mr-2">swap_horiz</span>
              <p>P2P Transfer</p>
            </button>

            {/* Activate ID Button */}
            <button
              onClick={() => setActivateOtherIdmodal(true)}
              className={`flex items-center justify-center font-semibold rounded-lg text-sm p-3 shadow-md ${
                dashboardDetails?.walletAmount > 15000
                  ? ""
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              style={{
                background:
                  dashboardDetails?.walletAmount > 15000
                    ? "linear-gradient(90deg, #ffecd2, #fcb69f)"
                    : undefined,
              }}
              disabled={!(dashboardDetails?.walletAmount > 15000)}
            >
              <span className="material-icons mr-2">verified_user</span>
              <p>Activate ID</p>
            </button>

            {/* Upgrade Plan Button */}
            <button
              onClick={() => setUpgradeModal(true)}
              className={`flex items-center justify-center font-semibold rounded-lg text-sm p-3 shadow-md ${
                dashboardDetails?.walletAmount > 15000
                  ? ""
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
              style={{
                background:
                  dashboardDetails?.walletAmount > 15000
                    ? "linear-gradient(90deg, #f6d365, #fda085)"
                    : undefined,
              }}
              disabled={!(dashboardDetails?.walletAmount > 15000)}
            >
              <span className="material-icons mr-2">upgrade</span>
              <p>Upgrade Plan</p>
            </button>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-4 grid-cols-2 text-white"></div>

      <div className="h-screen scrollable-content">
        <div className="grid lg:grid-cols-4 grid-cols-1 text-white">
          <AccountDetailsBox
            accountNumber={dashboardDetails?.accountNumber}
            bankName={dashboardDetails?.bankName}
            ifscCode={dashboardDetails?.ifscCode}
            icon={<MdOutlineNumbers size={32} />}
          />
          <UserMoneyBox
            name="Total Withdraw"
            // percent={-66}
            money={dashboardDetails?.withdarw}
            icon={<FaMoneyBillWave size={32} />}
          />

          <NormalBox
            name="Referral Member"
            // percent={-66}
            textData={dashboardDetails?.totalReferralMembers}
            icon={<FaUsersBetweenLines size={32} />}
          />

          <UserMoneyBox
            name="My Investment"
            // percent={-66}
            money={dashboardDetails?.joiningAmount}
            icon={<FaMoneyBillTrendUp size={32} />}
          />
        </div>
        <div className="grid lg:grid-cols-4 grid-cols-2 text-white">
          <NormalBox
            name="Downline Members"
            // percent={-66}
            textData={dashboardDetails?.downlineMembers}
            icon={<FaUsersViewfinder size={32} />}
          />
        </div>
        <div>
          {/* Modal for congratulations */}
          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <Confetti width={window.innerWidth} height={window.innerHeight} />
              <div className="bg-white rounded-lg shadow-lg p-6 w-96 text-center">
                <h2 className="text-4xl font-bold text-green-600 mb-4">
                  🎉 Congratulations! 🎉
                </h2>
                <p className="text-lg text-gray-800">
                  You have achieved{" "}
                  <span className="font-bold text-blue-500">
                    Level {rewardLevel}
                  </span>
                  !
                </p>
                <p className="text-md text-gray-600 mt-2">
                  Keep it up to enjoy more benefits in the future.
                </p>
                <button
                  onClick={() => setShowModal(false)}
                  className="mt-6 px-4 py-2 bg-blue-500 text-white rounded shadow hover:bg-blue-600 transition"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="h-screen">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-white p-4">
            {/* Pie Chart Component */}
            <div className="w-full flex justify-center">
              <div className="w-80 h-80 text-white">
                <Pie
                  data={pieData}
                  options={{
                    plugins: {
                      tooltip: {
                        callbacks: {
                          label: function (tooltipItem) {
                            const dataset = tooltipItem.dataset;
                            const value = dataset.data[tooltipItem.dataIndex];
                            return `${
                              tooltipItem.label
                            }: ₹ ${value.toLocaleString()}`;
                          },
                        },
                      },
                    },
                    animation: {
                      animateScale: true,
                      animateRotate: true,
                    },
                    maintainAspectRatio: false,
                  }}
                />
                <div className="text-center mt-4">
                  <p className="text-xl font-bold">
                    Achievement: ₹ {(achievement || 0).toLocaleString()}
                  </p>
                  <p className="text-lg">
                    Maximum: ₹ {(maximum || 0).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>

            {/* Level Card Component */}
            <div className="w-full flex justify-center">
              <div
                className="m-5 p-10 text-center neon-border bg-gradient-to-r from-transparent to-blue-800 rounded-lg shadow-xl place-self-center w-80"
                onClick={() => navigate("/user/Rewards")}
                style={{ cursor: "pointer" }}
              >
                <h2 className="text-2xl font-bold mb-4">Level in Progress</h2>
                <p className="text-xl">
                  <strong>Level:</strong> {levelData?.nextLevel}
                </p>
                <p className="text-lg">
                  <strong>Units Achieved:</strong> {levelData?.units}
                </p>
                <p className="text-lg">
                  <strong>Children Achieved:</strong> {levelData?.children}
                </p>
              </div>
            </div>
          </div>
        </div>

        {depositModal && (
          <DepositeModal
            depositModal={depositModal}
            setDepositeModal={setDepositeModal}
          />
        )}
        {isppModal && (
          <P2pPlanModal isppModal={isppModal} setIsppModalOpen={setIsppModal} />
        )}
        {isActivateOtherIdmodal && (
          <ActivateOtherIdModal
            isActivateOtherIdmodal={isActivateOtherIdmodal}
            setActivateOtherIdmodal={setActivateOtherIdmodal}
          />
        )}

        {widthDrawModal && (
          <WithdrawModal
            widthDrawModal={widthDrawModal}
            setWidthDrawModal={setWidthDrawModal}
          />
        )}
        {upgradeModal && (
          <UpgradePlanModal
            upgradeModal={upgradeModal}
            setUpgradeModal={setUpgradeModal}
          />
        )}
      </div>
    </div>
  );
};

export default UserHome;

const DepositeModal = ({ depositModal, setDepositeModal }) => {
  const [amountINR, setAmountINR] = useState("");
  const [amountUSDT, setAmountUSDT] = useState("");
  const conversionRate = 90; // 1 USDT = 90 INR
  const userBasicDetail = getStorageValue("userBasicDetail");

  const handleINRChange = (e) => {
    const inrValue = e.target.value;
    setAmountINR(inrValue);
    setAmountUSDT((inrValue / conversionRate).toFixed(2));
  };

  const handleUSDTChange = (e) => {
    const usdtValue = e.target.value;
    setAmountUSDT(usdtValue);
    setAmountINR((usdtValue * conversionRate).toFixed(2));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (!amountINR || !amountUSDT || amountINR <= 0 || amountUSDT <= 0) {
      alert("Please enter a valid amount.");
      return;
    }

    const payload = {
      userId: userBasicDetail._id,
      amount: amountINR,
      usdtAmount: amountUSDT,
    };

    try {
      // Call order creation service
      const orderId = await createDepositServices(payload);
      console.log(orderId.orderId, "orderID is");
      if (orderId) {
        // Create payment URL
        const paymentUrl = `${PAYMENT_URL}${orderId.orderId}`;

        // Redirect to payment page in a new tab
        window.open(paymentUrl, "_blank");
      } else {
        errorTost("Failed to create order. Please try again.");
      }
    } catch (error) {
      console.error("Error creating deposit order:", error);
      errorTost("An error occurred while processing your request.");
    }
  };

  return (
    <div className="w-screen h-screen bg-blue-950/60 z-50 fixed top-0 flex justify-center items-center">
      <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
        <form onSubmit={handleSubmit} className="w-[90%] mx-auto py-3">
          <p className="text-white text-2xl font-blackOps">Deposit</p>
          <div className="py-4">
            <label className="block text-white mb-2">Amount in INR</label>
            <input
              type="number"
              value={amountINR}
              onChange={handleINRChange}
              placeholder="Enter Amount in INR"
              className="border w-full outline-none p-2 rounded"
            />
          </div>
          <div className="py-4">
            <label className="block text-white mb-2">Amount in USDT</label>
            <input
              type="number"
              value={amountUSDT}
              onChange={handleUSDTChange}
              placeholder="Enter Amount in USDT"
              className="border w-full outline-none p-2 rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => setDepositeModal(false)}
              type="button"
              className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const WithdrawModal = ({ widthDrawModal, setWidthDrawModal }) => {
  const [amountINR, setAmountINR] = useState();
  const [amountUSDT, setAmountUSDT] = useState();
  const [walletAddress, setWalletAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const userBasicDetail = getStorageValue("userBasicDetail");

  const conversionRate = 90; // Conversion rate: 1 USDT = 90 INR
  const availableBalanceINR = userBasicDetail.walletAmount;
  const availableBalanceUSDT = (
    userBasicDetail.walletAmount / conversionRate
  ).toFixed(2);

  const chargeDeductionINR = (amountINR * 0.05).toFixed(2);
  const netAmountINR = (amountINR - chargeDeductionINR).toFixed(2);

  const chargeDeductionUSDT = (amountUSDT * 0.05).toFixed(2);
  const netAmountUSDT = (amountUSDT - chargeDeductionUSDT).toFixed(2);

  const handleINRChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value && parseFloat(value) > 15) {
      setError("Minimum $15 is required for withdrawal.");
    } else {
      setError(""); // Clear error if the value is valid
    }

    setAmountINR(value);
    setAmountUSDT((value / conversionRate).toFixed(2));
  };

  const handleUSDTChange = (e) => {
    const value = parseFloat(e.target.value);
    if (value && parseFloat(value) > 1000) {
      setError("Minimum 1000 is required for withdrawal.");
    } else {
      setError(""); // Clear error if the value is valid
    }
    setAmountUSDT(value);
    setAmountINR((value * conversionRate).toFixed(2));
  };

  const otpForWithdraw = async () => {
    if ((!amountINR && !amountUSDT) || !walletAddress) {
      setError("Please provide valid amount and wallet address.");
      return;
    }
    if (amountUSDT > availableBalanceUSDT || amountINR > availableBalanceINR) {
      setError("Insufficient balance for withdrawal.");
      return;
    }
    const payload = {
      amount: amountUSDT,
      walletAddress: walletAddress,
    };
    try {
      await generateOtpForWithdrawServices(payload);
      successTost("OTP sent successfully");
    } catch (error) {
      errorHandler(error);
    }
  };

  const widthDrawHandler = async (e) => {
    e.preventDefault();
    if ((!amountINR && !amountUSDT) || !walletAddress || !otp) {
      setError("Please fill in all fields correctly.");
      return;
    }
    if (amountUSDT < 14 || amountINR < 1000) {
      setError("MINIMUM $14 OR 1000 RUPEE CAN WITHDRAW");
      return;
    }
    if (amountUSDT > availableBalanceUSDT || amountINR > availableBalanceINR) {
      setError("Insufficient balance for withdrawal.");
      return;
    }

    const payload = {
      amount: amountUSDT,
      otp: otp,
      walletAddress: walletAddress,
    };
    try {
      await withdrawServices(payload);
      successTost("Withdraw successful");
      setWidthDrawModal(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="w-screen h-screen bg-blue-950/60 z-50 fixed top-0 flex justify-center items-center">
      <div className="w-full lg:w-[500px] bg-userTheme/80 rounded-lg">
        <form className="w-[90%] mx-auto py-3">
          <p className="text-white text-2xl font-blackOps">Withdraw</p>
          <div className="flex flex-col gap-y-4 py-4">
            <p className="text-white">
              Total Amount Available: {availableBalanceINR} (₹) /{" "}
              {availableBalanceUSDT} (USDT)
            </p>
            <div className="flex gap-x-4">
              <div className="w-1/2">
                <label
                  className="block text-white font-medium mb-1"
                  htmlFor="amountINR"
                >
                  Amount (INR)
                </label>
                <input
                  id="amountINR"
                  onChange={handleINRChange}
                  value={amountINR}
                  type="number"
                  placeholder="Enter Amount in INR"
                  className="border w-full outline-none rounded-lg p-2"
                />
              </div>
              <div className="w-1/2">
                <label
                  className="block text-white font-medium mb-1"
                  htmlFor="amountUSDT"
                >
                  Amount (USDT)
                </label>
                <input
                  id="amountUSDT"
                  onChange={handleUSDTChange}
                  value={amountUSDT}
                  type="number"
                  placeholder="Enter Amount in USDT"
                  className="border w-full outline-none rounded-lg p-2"
                />
              </div>
            </div>
            {amountINR > 0 && (
              <p className="text-white">
                -5% Charge: ₹{chargeDeductionINR} / Net Amount: ₹{netAmountINR}
              </p>
            )}
            {amountUSDT > 0 && (
              <p className="text-white">
                -5% Charge: {chargeDeductionUSDT} (USDT) / Net Amount:{" "}
                {netAmountUSDT} (USDT)
              </p>
            )}
            <label
              className="block text-white font-medium mb-1"
              htmlFor="walletAddress"
            >
              Wallet Address
            </label>
            <input
              id="walletAddress"
              onChange={(e) => setWalletAddress(e.target.value)}
              type="text"
              placeholder="Enter Wallet Address"
              className="border w-full outline-none rounded-lg p-2"
            />
            <label className="block text-white font-medium mb-1" htmlFor="otp">
              OTP
            </label>
            <input
              id="otp"
              onChange={(e) => setOtp(e.target.value)}
              type="number"
              placeholder="Enter OTP"
              className="border w-full outline-none rounded-lg p-2"
            />
            <p
              onClick={otpForWithdraw}
              className="text-theme ml-auto cursor-pointer font-semibold"
            >
              Get OTP
            </p>
            {error && <p className="text-red-500">{error}</p>}
          </div>
          <div className="flex justify-end ">
            <button
              onClick={() => setWidthDrawModal(false)}
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Cancel
            </button>

            <button
              onClick={widthDrawHandler}
              type="submit"
              className="text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const UpgradePlanModal = ({ upgradeModal, setUpgradeModal }) => {
  const [selectedUnit, setSelectedUnit] = useState(0); // Selected unit
  const userBasicDetail = getStorageValue("userBasicDetail");
  const verifiedUser = true; // Replace with actual verification logic if needed
  const [amountToPay, setAmountToPay] = useState(0); // Amount to pay
  const [loading, setLoading] = useState(false); // Loading state for submit button

  const calculateAmount = (unit) => {
    const amount = unit * 15000; // Calculate amount based on unit
    setAmountToPay(amount); // Store the calculated amount
  };

  const upgradePlanHandler = async (event) => {
    event.preventDefault();

    if (selectedUnit <= 0) {
      errorTost("Please select a valid unit before submitting.");
      return;
    }

    // Compare wallet balance with calculated amount
    if (userBasicDetail?.walletAmount < amountToPay) {
      errorTost("Insufficient balance to process the upgrade.");
      return;
    }

    setLoading(true); // Set loading state to true
    try {
      await upgradePlanServices({
        userId: userBasicDetail._id,
        amount: amountToPay,
      });
      successTost("New Package is upgraded");
      setTimeout(() => {
        setUpgradeModal(false); // Close the modal
        window.location.reload(); // Refresh the page
      }, 2000); // 2-second delay
    } catch (error) {
      console.error("Upgrade failed:", error);
      errorTost("Upgrade failed. Please try again.");
    } finally {
      setLoading(false); // Reset loading state in case of error
    }
  };

  return (
    <div className="w-screen h-screen bg-blue-950/60 z-50 fixed top-0 flex justify-center items-center">
      <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
        <form onSubmit={upgradePlanHandler} className="w-[90%] mx-auto py-3">
          <p className="text-white text-2xl font-blackOps">Upgrade Plan</p>
          <div className="flex items-start py-4 w-full flex-col">
            <label
              htmlFor="joiningAmount"
              className="block mb-2 text-sm font-medium text-white"
            >
              Select Unit
            </label>
            <div className="mb-5 w-full">
              <select
                disabled={!verifiedUser}
                name="joiningAmount"
                id="joiningAmount"
                onChange={(e) => {
                  const unit = parseInt(e.target.value);
                  setSelectedUnit(unit); // Update selected unit
                  calculateAmount(unit); // Calculate the amount to pay
                }}
                className="text-gray-900 text-sm bg-userTheme text-white rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="" selected>
                  Select Unit
                </option>
                {[...Array(15)].map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    Unit {index + 1}
                  </option>
                ))}
              </select>
            </div>
            {selectedUnit > 0 && (
              <div className="mt-3 text-white">
                <p>
                  You need to pay <b>₹{amountToPay}</b> or{" "}
                  <b>$ {(amountToPay / 90).toFixed(2)}</b> to activate the
                  account.
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setUpgradeModal(false)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              disabled={loading} // Disable Cancel button while loading
            >
              Cancel
            </button>

            <button
              type="submit"
              className={`text-white ml-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${
                loading ? "cursor-not-allowed opacity-50" : ""
              }`}
              disabled={loading} // Disable Submit button while loading
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const P2pPlanModal = ({ isppModal, setIsppModalOpen }) => {
  const [customerId, setcustomerId] = useState("");
  const [userData, setUserData] = useState(null);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [amount, setAmount] = useState("");
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(false);
  const [isOtpVerificationInProgress, setIsOtpVerificationInProgress] =
    useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false); // S
  const userBasicDetail = getStorageValue("userBasicDetail");
  const verifyCustomeridHandler = async () => {
    try {
      setIsVerifyButtonDisabled(true); // Disable the button
      if (userBasicDetail?.walletAmount < 1000) {
        errorTost("Insufficient balance to process P2P transfer");
        setIsVerifyButtonDisabled(false); // Re-enable if error occurs
        return;
      }

      let payload = { ownId: customerId };

      if (customerId === userBasicDetail.ownId) {
        errorTost("Receiver is not same as Sender");
        setIsVerifyButtonDisabled(false); // Re-enable if error occurs
        return;
      }

      const response = await verifyUser(payload);
      if (response.status === true) {
        const data = response.user; // Assuming response has a `user` object
        setUserData(data);
        successTost(`${customerId} user verified: ${data?.name}`);
      } else {
        errorTost("Customer ID verification failed. Please try again.");
        setIsVerifyButtonDisabled(false); // Re-enable if error occurs
      }
    } catch (error) {
      console.error("Error verifying Customer ID:", error);
      errorTost("Error verifying Customer ID. Please try again.");
    } finally {
      setIsVerifyButtonDisabled(false); // Always re-enable the button
    }
  };

  const sendOtpHandler = async () => {
    try {
      if (!userData || !amount) {
        errorTost(
          "Please verify Customer ID and enter Amount before sending OTP."
        );
        return;
      }

      if (amount < 1000) {
        errorTost("Minimum 1000 amount is required for withdraw");
        return;
      }

      let payload = {
        userId: userBasicDetail._id,
        amount: amount,
        recieverId: customerId,
      };

      const response = await generateOtpForp2pServices(payload);
      if (response.msg === "Success") {
        setIsOtpSent(true);
        successTost("OTP sent successfully. Please check your email.");
      } else {
        errorTost("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      errorTost("Error sending OTP. Please try again.");
    }
  };

  const verifyOtpHandler = async () => {
    try {
      setIsOtpVerificationInProgress(true); // Disable the button and set progress state
      if (!otp) {
        errorTost("Please enter the OTP.");
        setIsOtpVerificationInProgress(false); // Re-enable if error occurs
        return;
      }

      let payload = {
        userId: userBasicDetail._id,
        otp: otp,
        amount: amount,
        recieverId: customerId,
      };

      const response = await verifyOtpForp2pServices(payload);

      if (response.msg === "Success") {
        setIsOtpVerified(true);
        successTost(
          `Transaction successful! ₹${amount} sent to ${userData.name}.`
        );
        setTimeout(() => {
          setIsppModalOpen(false); // Close the modal
          window.location.reload(); // Refresh the page
        }, 2000); // 2-second delay
      } else {
        errorTost("Invalid OTP or transaction failed. Please try again.");
        setIsOtpVerificationInProgress(false); // Re-enable if error occurs
      }
    } catch (error) {
      console.error("Error verifying OTP and sending amount:", error);
      errorTost("An error occurred during the transaction. Please try again.");
    } finally {
      setIsOtpVerificationInProgress(false); // Always re-enable the button
    }
  };

  return (
    <div className="w-screen h-screen bg-blue-950/60 z-50 fixed top-0 flex justify-center items-center">
      <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg">
        <form className="w-[90%] mx-auto py-3">
          <p className="text-white text-2xl font-blackOps">P2P Transfer</p>
          {/* Customer ID Verification */}
          <div className="py-4">
            <label className="block mb-2 text-sm font-medium text-white">
              Enter Receiver CustomerID
            </label>
            <input
              type="number"
              value={customerId}
              onChange={(e) => setcustomerId(e.target.value)}
              className="block w-full p-2 mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
              placeholder="Enter Receiver CustomerID"
            />
            <button
              type="button"
              onClick={verifyCustomeridHandler}
              className={`text-white px-5 py-2 rounded-lg ${
                isVerifyButtonDisabled
                  ? "bg-green-500"
                  : "bg-blue-700 hover:bg-blue-800"
              }`}
              disabled={isVerifyButtonDisabled}
            >
              {isVerifyButtonDisabled ? "Verified" : "Verify"}
            </button>
          </div>

          {userData && (
            <>
              <div className="py-4">
                <p className="text-white">
                  Receiver Name: <b>{userData.name}</b>
                </p>
                <p className="text-white">
                  Receiver Email: <b>{userData.email}</b>
                </p>
              </div>

              {/* Amount and OTP Section */}
              <div className="py-4">
                <label className="block mb-2 text-sm font-medium text-white">
                  Enter Amount
                </label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="block w-full p-2 mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                  placeholder="Enter amount"
                />

                <button
                  type="button"
                  onClick={async () => {
                    setIsOtpLoading(true); // Set loading state to true
                    await sendOtpHandler(); // Call the OTP sending function
                    setIsOtpLoading(false); // Set loading state to false after the function execution
                  }}
                  className={`text-white px-5 py-2 rounded-lg ${
                    isOtpLoading || isOtpSent
                      ? "bg-green-500 opacity-50 pointer-events-none"
                      : "bg-blue-700 hover:bg-blue-800"
                  }`}
                  disabled={isOtpLoading || isOtpSent} // Disable when loading or OTP already sent
                >
                  {isOtpLoading
                    ? "Sending OTP..."
                    : isOtpSent
                    ? "OTP Sent"
                    : "Send OTP"}
                </button>
              </div>

              {isOtpSent && (
                <div className="py-4">
                  <label className="block mb-2 text-sm font-medium text-white">
                    Enter OTP
                  </label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="block w-full p-2 mb-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                    placeholder="Enter OTP"
                  />
                  <button
                    type="button"
                    onClick={verifyOtpHandler}
                    className={`text-white px-5 py-2 rounded-lg ${
                      isOtpVerificationInProgress
                        ? "bg-green-500"
                        : "bg-blue-700 hover:bg-blue-800"
                    }`}
                    disabled={isOtpVerificationInProgress}
                  >
                    {isOtpVerificationInProgress
                      ? "Transferring..."
                      : "Verify & Send"}
                  </button>
                </div>
              )}
            </>
          )}

          {/* Cancel Button */}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => setIsppModalOpen(false)}
              className="text-white bg-gray-600 hover:bg-gray-700 px-5 py-2 rounded-lg"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ActivateOtherIdModal = ({
  isActivateOtherIdmodal,
  setActivateOtherIdmodal,
}) => {
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false); // For API calls
  const [activationLoading, setActivationLoading] = useState(false); // For activation process
  const userBasicDetail = getStorageValue("userBasicDetail"); // Retrieve logged-in user's details

  const verifyUserHandler = async () => {
    if (!email) {
      errorTost("Please enter an email address.");
      return;
    }
    setLoading(true);
    try {
      const response = await verifyUserByEmailService({ email }); // Replace with actual API call
      if (response.msg === "Success") {
        setUserData(response.result[0]);
        successTost("User verified successfully!");
      } else {
        errorTost(response.message || "User verification failed.");
      }
    } catch (error) {
      console.error("Error verifying user:", error);
      errorTost("Failed to verify user. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const activateHandler = async () => {
    if (!userData) return;

    setActivationLoading(true);
    try {
      const payload = {
        orderId:userData.orderId,
        userId:userBasicDetail._id
      };
      
      const response = await activateUserService(payload); // Replace with actual API call
      if (response.msg === "Success") {
        successTost("User activated successfully!");
        setTimeout(() => {
          setActivateOtherIdmodal(false);
          window.location.reload();
        }, 2000);
      } else {
        errorTost(response.message || "Activation failed.");
      }
    } catch (error) {
      console.error("Error activating user:", error);
      errorTost("Failed to activate user. Please try again.");
    } finally {
      setActivationLoading(false);
    }
  };

  const clearHandler = () => {
    setEmail("");
    setUserData(null);
  };

  return (
    <div
      className={`w-screen h-screen bg-blue-950/60 z-50 fixed top-0 left-0 flex justify-center items-center ${
        isActivateOtherIdmodal ? "" : "hidden"
      }`}
    >
      <div className="w-full lg:w-[400px] bg-userTheme/80 rounded-lg p-6 shadow-xl">
        <p className="text-white text-2xl font-blackOps text-center mb-4">
          ID Activate
        </p>

        <form>
          {/* Email Input */}
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-white">
              Enter Email
            </label>
            <div className="flex items-center gap-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-1 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                placeholder="Enter user's email"
                disabled={loading || activationLoading || !!userData}
              />
              <button
                type="button"
                onClick={verifyUserHandler}
                className={`text-white px-4 py-2 rounded-lg ${
                  userData
                    ? "bg-green-600 cursor-default"
                    : loading
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-700 hover:bg-blue-800"
                }`}
                disabled={loading || activationLoading || !!userData}
              >
                {loading ? "Verifying..." : "Verify"}
              </button>
              {userData && (
                <button
                  type="button"
                  onClick={clearHandler}
                  className="text-white bg-gray-600 hover:bg-gray-700 px-4 py-2 rounded-lg"
                >
                  Clear
                </button>
              )}
            </div>
          </div>

          {/* User Details */}
          {userData && (
            <div className="mb-4 text-white">
              <p>
                <b>Order ID:</b> {userData.orderId}
              </p>
              <p>
                <b>Email:</b> {userData.email}
              </p>
              <p>
                <b>Amount to Pay:</b> ₹{userData.amount}
              </p>
              <p>
                <b>Your Wallet Balance:</b> ₹{userBasicDetail.walletAmount}
              </p>
            </div>
          )}

          {/* Action Buttons */}
          <div className="flex justify-between items-center mt-4">
            {userData && (
              <button
                type="button"
                onClick={activateHandler}
                className={`text-white px-5 py-2 rounded-lg ${
                  userBasicDetail.walletAmount >= userData.amount
                    ? "bg-blue-700 hover:bg-blue-800"
                    : "bg-gray-500 cursor-not-allowed"
                }`}
                disabled={
                  userBasicDetail.walletAmount < userData.amount ||
                  activationLoading
                }
              >
                {activationLoading ? "Processing..." : "Activate"}
              </button>
            )}
            <button
              type="button"
              onClick={() => setActivateOtherIdmodal(false)}
              className="text-white bg-gray-600 hover:bg-gray-700 px-5 py-2 rounded-lg"
              disabled={loading || activationLoading}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

