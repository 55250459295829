import axios from "../../utils/Axios";
import { BASE_URL, BASE_FILE_PATH } from "../../utils/Urls";

export const getUserDetailService = async () => {
    let url = `${BASE_URL}user/getUserDetails`
    return await axios.get(url)
}
export const getOrderDetail = async (payload) => {
  let url = `${BASE_URL}auth/getOrderdetail`
  return await axios.post(url, payload)
}

export const adminDashBoardServices = async () => {
    let url = `${BASE_URL}user/getDashboardDetails`;
    return await axios.get(url,);
  };

  export const refferralsListServices = async () => {
    let url = `${BASE_URL}user/getDirectTeamMember`;
    return await axios.get(url);
  };

  export const getAllMemberListServices = async (payload) => {
    let url = `${BASE_URL}user/getAllTeamMember`;
    return await axios.get(url, payload);
  };


  
  export const mybussinessListServices = async () => {
    let url = `${BASE_URL}user/getBusinessHistory`;
    return await axios.get(url,);
  };

  export const mytranssactionListServices = async () => {
    let url = `${BASE_URL}user/getTransactionHistory`;
    return await axios.get(url,);
  };
  
  export const getDirectIncomeListServices = async () => {
    let url = `${BASE_URL}user/getDirectIncome`;
    return await axios.get(url,);
  };

  export const getLevelWiseIncomeServices = async (type) => {
    let url = `${BASE_URL}user/getLevelWiseIncome/${type}`;
    return await axios.get(url,);
  };
  

  export const updatePaymentDetailsServices = async (payload) => {
    let url = `${BASE_URL}user/updatePaymentDetails`;
    return await axios.put(url,payload);
  };

  export const createDepositServices = async (payload) => {
    let url = `${BASE_URL}user/createDepositOrder`;
    return await axios.post(url,payload);
  };

  export const updateJoiningDetailsServices = async (payload) => {
    let url = `${BASE_URL}user/updateJoiningDetails`;
    return await axios.put(url,payload);
  };

  export const generateOtpForWithdrawServices = async (payload) => {
    let url = `${BASE_URL}user/generateOtpForWithdraw`;
    return await axios.post(url,payload);
  };
  export const generateOtpForp2pServices = async (payload) => {
    let url = `${BASE_URL}user/generateOtpForp2p`;
    return await axios.post(url,payload);
  };
  export const verifyOtpForp2pServices = async (payload) => {
    let url = `${BASE_URL}user/otpp2pverifyandsend`;
    return await axios.post(url,payload);
  };
  export const upgradePlanServices = async (payload) => {
    let url = `${BASE_URL}user/manualTopup`;
    return await axios.post(url,payload);
  };
  
  export const createWithdrawalRequestServices = async (payload) => {
    let url = `${BASE_URL}user/withdrawalrequest/create`;
    return await axios.post(url,payload);
  };
  export const getWithdrawalRequestServices = async (payload) => {
    console.log(payload, "payload")
    const url = `${BASE_URL}user/withdrawalrequest`;

    console.log("URL:", url);
    return await axios.post(url, payload); // Removed payload from `axios.get` since params are already in the URL
  };
  export const verifyUser = async (data) => {
    let url = `${BASE_URL}user/verifyid`;
    return await axios.post(url, data);
  };
  
  export const withdrawServices = async (payload) => {
    let url = `${BASE_URL}user/withdraw`;
    return await axios.post(url,payload);
  };
  

  export const verifyUserByEmailService = async (payload) => {
    let url = `${BASE_URL}user/getuserdetailactivation`;
    return await axios.post(url,payload);
  };

  export const activateUserService = async (payload) => {
    let url = `${BASE_URL}user/otheridactivation`;
    return await axios.post(url,payload);
  };
  

  export const updateAutoTopServices = async (payload) => {
    let url = `${BASE_URL}user/updateAutoTop`;
    return await axios.put(url,payload);
  };
  
  export const updateProfileServices = async (payload) => {
    let url = `${BASE_URL}user/updateProfileDetails`;
    return await axios.put(url,payload);
  };

 
  export const getMemberTreeService = async (payload) => {
    let url = `${BASE_URL}user/user-tree`;
    return await axios.post(url,payload);
  };


/** Create a new activation request */
export const createActivationRequestService = async (payload) => {
  const url = `${BASE_URL}user/activation-request/create`;
  return await axios.post(url, payload);
};

export const verifyCryptoPaymentService = async (payload) => {
  const url = `${BASE_URL}payment`;
  return await axios.post(url,payload);
}
/** Get activation request by ID */
export const getActivationRequestService = async (id) => {
  const url = `${BASE_URL}${id}`;
  return await axios.get(url);
};

/** Update an activation request */
export const updateActivationRequestService = async (id, payload) => {
  const url = `${BASE_URL}${id}`;
  return await axios.put(url, payload);
};

/** Get all activation requests for a specific user */
export const getActivationRequestsByUserService = async (userId) => {
  const url = `${BASE_URL}user/${userId}`;
  return await axios.get(url);
};
export const refreshRewardList = async (userId) => {
  const url = `${BASE_URL}user/calculate-rewards?userId=${userId}`;
  return await axios.get(url); // No need to pass userId here
};


  export const uploadScreenshotService = async (screenshot) => {
    let url = `${BASE_FILE_PATH}/uploadscreenshot`
    let formData = new FormData()
    formData.append('image', screenshot)
    console.log("In assest")
    
    return await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
export const updateProfilePictureServices = async (payload) => {
  let url = `${BASE_URL}user/updateProfileImage`;
  return await axios.put(url,payload);
};

export const uploadProfilePictureServices = async (profile) => {
  let url = `${BASE_FILE_PATH}/uploadprofileimage`
  let formData = new FormData()
  formData.append('image', profile)
  console.log("In assest")
  
  return await axios.post(url, formData, {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  })
}



  // epxo
//   getLevelWiseIncome

